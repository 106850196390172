import React, { Component } from 'react';
import { Button } from '../common/index';
import Lightbox from 'react-images';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import media from '../layout/Media';
const HeaderButtonTran = styled(Button)`
  border: 2px solid #dbdbdb;
  background: ${props => props.theme.black};
  margin-left: 0.5rem;
  color: #dbdbdb;
  @media ${media.mobileL} {
    width: 200px;
  }
`;

/**
 * A compnent for showing a hidden gallery in a modal box. Eg of use - On button click show a gallery in a modal
 */
export default class Gal extends Component {
  constructor() {
    super();

    this.state = {
      lightboxIsOpen: false,
      currentImage: 0
    };

    this.closeLightbox = this.closeLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
    this.gotoImage = this.gotoImage.bind(this);
    this.handleClickImage = this.handleClickImage.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
  }
  openLightbox(index, event) {
    event.preventDefault();
    this.setState({
      currentImage: index,
      lightboxIsOpen: true
    });
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false
    });
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1
    });
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1
    });
  }
  gotoImage(index) {
    this.setState({
      currentImage: index
    });
  }
  handleClickImage() {
    if (this.state.currentImage === this.props.images.length - 1) return;

    this.gotoNext();
  }

  render() {
    return (
      <>
        <HeaderButtonTran primary onClick={e => this.openLightbox(0, e)}>
          {this.props.button}
        </HeaderButtonTran>

        <Lightbox
          currentImage={this.state.currentImage}
          images={this.props.images}
          isOpen={this.state.lightboxIsOpen}
          onClickImage={this.handleClickImage}
          onClickNext={this.gotoNext}
          onClickPrev={this.gotoPrevious}
          onClickThumbnail={this.gotoImage}
          onClose={this.closeLightbox}
          caption={this.props.images}
          alt={this.props.images}
        />
      </>
    );
  }
}

Gal.propTypes = {
  /** Array of images to be displayed in lightbox */
  images: PropTypes.array.isRequired
};

export { Gal };
