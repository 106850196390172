import React from 'react';
import { injectStripe, CardElement } from 'react-stripe-elements';
import { FormGroup, Input, FormGroup2col } from '../layout/FormStyles';
import { Button } from '../common/index';
import { Error } from '../layout/';
import styled from 'styled-components';
import formatMoney from '../../lib/formatMoney';
import stripeImage from '../images/powered_by_stripe.svg';
import visa from './../images/visa.svg';
import mastercard from './../images/mastercard.svg';
import letsEn from './../images/lets-encrypt-badge-39203ada5d6983dbd12a1ad01ae9aa93691bcd71bcfe5cbd47a9de47c6f4a1f4.png';

const Trust = styled.div`
  img {
    margin: 0;
    display: block;
  }
`;

const CheckoutFormWrapper = styled.form`
  label {
    display: block;
    padding-bottom: 5px;
  }
  .cardInput {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-top: 10px;
  }
`;

class CheckoutForm extends React.Component {
  state = {
    fname: '',
    lname: '',
    email: '',
    disabled: false,
    buttonText: 'BUY NOW',
    paymentMessage: '',
    chargeId: '',
    status: '',
    data: {}
  };

  resetForm = () => {
    this.setState({
      disabled: false,
      buttonText: 'BUY NOW',
      paymentMessage: '',
      chargeId: '',
      status: '',
      data: {},
      error: ''
    });
    () => this.props.closeForm;
  };

  saveToState = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = ev => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();
    this.setState({ disabled: true, buttonText: 'Processing...' });
    // Within the context of `Elements`, this call to createToken knows which Element to
    // tokenize, since there's only one in this group.
    const amount = this.props.data.amount;
    const currency = this.props.data.currency;
    const description = this.props.data.title + ' - ' + this.props.data.varient;

    this.props.stripe
      .createToken({
        name: this.state.fname + ' ' + this.state.lname,
        email: this.state.email
      })

      .then(({ token, error }) => {
        if (error) {
          console.log(error);
          this.setState({
            disabled: false,
            buttonText: 'Try Again',
            error: error.message
          });
        } else {
          console.log('Received Stripe token:', token);
          fetch('/.netlify/functions/purchase', {
            method: 'POST',
            mode: 'no-cors',
            body: JSON.stringify({
              token,
              amount,
              currency,
              description,
              metadata: {
                'First Name': this.state.fname,
                'Last name': this.state.lname,
                Email: this.state.email
              }
            }),
            headers: new Headers({
              'Content-Type': 'application/json'
            })
          })
            .catch(error => {
              console.error('Error:', error);
            })
            .then(res => {
              console.log('res', res);
              res.json().then(json => {
                if (json.error) {
                  this.setState({
                    disabled: false,
                    buttonText: 'Try again?',
                    paymentMessage: json.message,
                    error: json.error
                  });
                } else {
                  this.setState({
                    disabled: false,
                    buttonText: 'Thanks! Buy again?',
                    chargeId: json.charge.id,
                    paymentMessage: json.message,
                    status: json.charge.status,
                    data: json.charge
                  });

                  let nosym = formatMoney(amount, true, true);

                  // adds tap affiliate tracking code
                  var metaData = {
                    productName: description
                  };

                  tap('create', '618-b75ba7');
                  tap(
                    'conversion',
                    json.charge.id,
                    nosym,
                    {
                      meta_data: metaData
                    }
                    
                  );

                  // Adds Google analytics tracking for purchase

                  dataLayer.push({
                    event: 'Purchase',
                    ecommerce: {
                      purchase: {
                        actionField: {
                          id: json.charge.id, // Transaction ID. Required for purchases and refunds.
                          revenue: nosym // Total transaction value (incl. tax and shipping)
                        },
                        products: [
                          {
                            // List of productFieldObjects.
                            name: description, // Name or ID is required.
                            price: nosym,
                            quantity: 1,
                            coupon: '' // Optional fields may be omitted or set to empty string.
                          }
                        ]
                      }
                    }

                    // transactionId: json.charge.id,
                    // transactionTotal: nosym,
                    // transactionProducts: [
                    //   {
                    //     sku: 'e123',
                    //     name: description,
                    //     category: this.props.data.title,
                    //     price: nosym,
                    //     quantity: 1
                    //   }
                    // ]
                  });
                }
              });
            });
        }
      });
  };

  render() {
    const props = this.props;

    if (this.state.status === 'succeeded')
      return (
        <div>
          <h1>Hot dog! That was a success!</h1>

          <p>
            Thanks so much for your booking. You have been sent a confirmation
            email to {this.state.data.receipt_email} with a record of your
            purchase and any contact details required for confirming things like
            arrival or tour dates.
          </p>
          <h2>Your booking details</h2>
          <ul>
            <li>
              <strong>Your Confirmation number: </strong>
              {this.state.data.id}
            </li>
            <li>
              <strong>You just purchased: </strong>
              {this.state.data.description}
            </li>
            <li>
              <strong>You paid: </strong>
              {formatMoney(this.state.data.amount)}
            </li>
          </ul>
          <button
            primary
            onClick={e => {
              {
                this.resetForm(e);
              }
              {
                props.closeForm(e);
              }
            }}
          >
            Close and continue browsing
          </button>
        </div>
      );
    return (
      <>
        <CheckoutFormWrapper onSubmit={this.handleSubmit}>
          <h1>Whooo! Let's book this</h1>

          <p>
            You are buying a{' '}
            <strong>
              {props.data.title} - {props.data.varient}
            </strong>{' '}
            for{' '}
            <strong>
              AU
              {formatMoney(props.data.amount)}
            </strong>
            . Enter your details below to secure your booking.
          </p>

          <FormGroup2col col="2" mobcol="1">
            <div>
              <label htmlFor="fname">First name</label>
              <Input
                id="fname"
                type="text"
                name="fname"
                value={this.state.fname}
                onChange={e => this.saveToState(e)}
                required
                placeholder="e.g James"
                autoFocus
                spellcheck="false"
              />
            </div>
            <div>
              <label htmlFor="lname">Last name</label>
              <Input
                id="lname"
                name="lname"
                type="text"
                value={this.state.lname}
                onChange={e => this.saveToState(e)}
                required
                placeholder="e.g Smith"
                spellcheck="false"
              />
            </div>
          </FormGroup2col>
          <FormGroup>
            <label htmlFor="email">Your email</label>
            <Input
              id="email"
              name="email"
              type="email"
              value={this.state.email}
              onChange={e => this.saveToState(e)}
              required
              placeholder="e.g james@gmail.com"
              spellcheck="false"
            />
          </FormGroup>
          <FormGroup>
            <label>
              Card details
              <span style={{ float: 'right' }}>
                <img src={mastercard} alt="You can use mastercard" />
                <img src={visa} alt="You can use visa" />
              </span>
              <CardElement
                className="cardInput"
                style={{ base: { fontSize: '18px' } }}
                onChange={
                  ('change',
                  ({ error }) => {
                    if (error) {
                      this.setState({ error: error.message });
                    } else {
                      this.setState({ error: '' });
                    }
                  })
                }
              />
            </label>
          </FormGroup>
          {this.state.error && <Error>{this.state.error}</Error>}
          <Button disabled={this.state.disabled} primary>
            {this.state.buttonText}
          </Button>
        </CheckoutFormWrapper>
        <Trust>
          <img src={stripeImage} alt="Secure payments by Stripe" />
          <img src={letsEn} alt="SSL by Let's Encrypt" width="110px" />
        </Trust>
      </>
    );
  }
}

export default injectStripe(CheckoutForm);
