import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import Link from 'gatsby-link';
import LocalisedLink from './locallisedLink';
import PropTypes from 'prop-types';
import media from '../layout/Media';

const CategoryItem = styled.div`
  -ms-grid-row: 1;
  -ms-grid-column: 1;

  margin: 0;
  overflow: hidden;
  position: relative;
  z-index: 2;
  min-height: 150px;
  @media ${media.laptopL} {
    min-height: 200px;
  }
  padding: 0 0 1rem;
  transition: all 0.6s ease-in-out;
  .gatsby-image-wrapper {
    transition: all 0.6s ease-in-out;
    filter: brightness(0.8);
    &:hover {
      transform: scale(1.2);
      filter: brightness(1);
    }
  }

  h3 {
    margin: 0px;
    overflow: hidden;
    position: absolute;
    z-index: 2;
    margin-top: 15%;
    left: 0;
    font-size: 1.3rem;
    letter-spacing: 0.04rem;
    padding: 1rem;
    text-align: center;
    line-height: 1.3rem;
    color: ${props => props.theme.white};
    width: 100%;

    @media ${media.laptopL} {
      padding-top: 8%;
    }
  }
`;

function FluidGallery(props) {
  return (
    <CategoryItem className={`f-${props.index}`}>
      {props.id === 'bedhopper' ? (
        <LocalisedLink to={`${props.url}/`}>
          <Img
            fluid={props.fluid}
            alt={props.alt}
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              overflow: 'hidden',
              zIndex: 1
            }}
          />
          <h3>{props.title}</h3>
        </LocalisedLink>
      ) : (
        <LocalisedLink to={`hostels/${props.url}/`}>
          <Img
            fluid={props.fluid}
            alt={props.alt}
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              overflow: 'hidden',
              zIndex: 1
            }}
          />
          <h3>{props.title}</h3>
        </LocalisedLink>
      )}
    </CategoryItem>
  );
}

FluidGallery.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  fluid: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired
};

export { FluidGallery };
