import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import styled from 'styled-components';
import SiteLogo from '../images/small-nomads-logo.png';
import SiteLogoDark from '../images/small-nomads-logo.png';
import { Button } from '../common';
import { Nav, MenuBooking } from '../layout/index.js';

const Logo = styled.div`
  position: relative;
`;

const LogoWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 0px;
  width: 110px;
  @media (max-width: 900px) {
    left: 0;
    top: 10px;
  }
`;

const Menu = styled.div`
  text-align: left;
  padding: 0.8rem 0 0.8rem 0.8rem;
  justify-self: end;
  float: right;
`;

const LocalisedNavLink = styled.a`
  display: inline-block;
  text-align: left;
  padding: 5px 10px;
  text-decoration: none;
  font-size: 1rem;
  float: left;
`;
const NavLink = styled.a`
  display: inline-block;
  text-align: left;
  padding: 5px 10px;
  text-decoration: none;
  font-size: 1rem;
  float: left;
`;

const NavButton = styled.a`
  float: left;
  display: inline-block;
  color: #f2f2f2;
  text-align: center;
  padding: 0px 10px;
  text-decoration: none;
  font-size: 1rem;
`;

const MenuSpan = styled.span`
  font-weight: bold;
  font-size: 1.3rem;
`;

/** The main navigation component for the site */
const Navigation = props => {
  const [showMenu, setShowMenu] = useState(false);
  const [showDraw, setShowDraw] = useState(false);

  const toggleDraw = () => {
    setShowDraw(!showDraw);
  };
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const burgerActive = showMenu ? 'responsive' : '';
  return (
    <Nav darkmenu={props.darkmenu}>
      <MenuBooking open={showDraw} toggleDraw={toggleDraw} />
      <Logo>
        <LogoWrapper>
          <a href="https://nomadsworld.com">
            {props.darkmenu ? (
              <img
                src={SiteLogoDark}
                style={{ width: 110 }}
                alt="Base Backpackers Australia and new zealand"
              />
            ) : (
              <img
                src={SiteLogo}
                style={{ width: 110 }}
                alt="Base Backpackers Australia and new zealand"
              />
            )}
          </a>
        </LogoWrapper>
      </Logo>
      <Menu id="Topnav" className={`topnav ${burgerActive} `}>
        <LocalisedNavLink
          className="amlink"
          href="https://nomadsworld.com/destinations"
        >
          Locations
        </LocalisedNavLink>
        <LocalisedNavLink
          className="amlink"
          href="https://nomadsworld.com/groups/"
        >
          Groups
        </LocalisedNavLink>
        <NavLink
          className="amlink"
          href="https://nomadsworld.com/product-category/deals/"
        >
          Deals
        </NavLink>
        <NavLink className="amlink" href="https://nomadsworld.com/blog/">
          Blog
        </NavLink>

        <div className="dropdown">
          <button className="dropbtn amlink">Social</button>
          <div className="dropdown-content">
            <a
              href="https://www.instagram.com/nomadshostels/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>

            <a
              href="https://www.facebook.com/NomadsHostels/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
          </div>
        </div>
        <div className="dropdown ">
          <button className="dropbtn amlink">Work</button>
          <div className="dropdown-content">
            <a href="https://nomadsworld.com/work-and-travel/">
              Work & Travel Packages
            </a>
            <a href="https://nomadsworld.com/work-and-travel/careers/">
              Nomads Careers
            </a>
            <a href="https://nomadsworld.com/work-and-travel/internships/">
              Internships
            </a>
          </div>
        </div>

        {props.hostel ? (
          <Link to="/book">
            <Button primary className="menucta distributor">
              Book now
            </Button>
          </Link>
        ) : (
          <Button primary className="menucta" onClick={toggleDraw}>
            Book now
          </Button>
        )}

        <NavButton className="icon" href="#" onClick={toggleMenu}>
          <MenuSpan>Menu</MenuSpan> &#9776;
        </NavButton>
      </Menu>
    </Nav>
  );
};

Navigation.propTypes = {
  /** Switches between a dark and light menu. Supplied by header component */
  darkmenu: PropTypes.bool,
  /** Determins if this is a hostel page or not if it is it will change the action of the booking button */
  hostel: PropTypes.bool
};

export { Navigation };
