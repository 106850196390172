import styled from 'styled-components';

/** A simple styled component for showing nav emelents */
const Nav = styled.nav`
  grid-area: nav;
  display: grid;
  grid-template-columns: 120px auto;
  color: black;
  z-index: 2;
  position: relative;
  /* padding: 1.5rem; */
  @media screen and (max-width: 900px) {
    .topnav a,
    .searchbar,
    .dropdown .dropbtn,
    .menucta {
      display: none;
    }
    .topnav a.icon {
      float: right;
      display: block;
      font-size: 1.5rem;
      margin: 0;
    }
  }

  @media screen and (max-width: 900px) {
    .topnav.responsive {
      position: absolute;
      padding: 2rem;
      background: #000000ab;
      min-width: 200px;
    }
    .topnav.responsive a.icon {
      position: absolute;
      right: 0;
      top: 0;
    }
    .topnav.responsive .searchbar {
      display: flex;
    }
    .topnav.responsive a {
      float: none;
      display: block;
      text-align: left;
      font: size 1.4rem;
      line-height: 3rem;
    }
    .topnav.responsive button {
      float: none;
      display: block;
      text-align: left;
      font: size 1.4rem;
      line-height: 3rem;
    }
    .topnav.responsive .dropdown {
      float: none;
    }
    .topnav.responsive .dropdown-content {
      position: relative;
      font-size: 1rem;
    }
    .topnav.responsive .dropdown .dropbtn {
      display: block;
      width: 100%;
      text-align: left;
    }
  }

  a {
    color: ${props => (props.darkmenu ? 'black' : 'white')};
  }
  .dropdown {
    float: left;
    overflow: hidden;
  }

  .amlink::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #fff;
    transition: width 0.3s;
  }

  .amlink:hover::after {
    width: 100%;
    //transition: width .3s;
  }

  .dropdown .dropbtn {
    border: none;
    outline: none;
    color: ${props => (props.darkmenu ? 'black' : 'white')};
    padding: 5px 10px;
    background-color: transparent;
    font-family: inherit;
    margin: 0;
    font-size: 1rem;
  }
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #000000ab !important;
    min-width: 140px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 5px 0;
  }

  .dropdown-content a {
    float: none;
    color: black;
    padding: 5px 15px;
    text-decoration: none;
    display: block;
    text-align: left;
    color: #fff !important;
  }

  .dropdown-content a:hover {
    background-color: #000000ab !important;
  }

  .dropdown:hover .dropdown-content {
    display: block;
    background-color: #000000ab !important;
  }

  .dropdown:hover .dropbtn:hover {
    background-color: transparent;
    color: white;
  }

  @media (max-width: 900px) {
    padding: 0;
  }
`;

export { Nav };
