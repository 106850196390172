import React from 'react';

import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import styled, { ThemeProvider } from 'styled-components';
import CookieConsent from 'react-cookie-consent';
import { Footer, GlobalStyle } from '../components/layout/index.js';
import DataLayer from './layout/DataLayer';

import baseTheme from './theme/base';

const Body = styled.main`
  grid-area: body;
  background: #fcfcfc;
  position: relative;
  width: 100%;
  border-top: 3px solid ${props => props.theme.primaryColor};
`;

/**
 * This is the main layout comoneny of the page and wraps around all content. It provides a basic grid layout and injects the footer into all pages. In addition it also supplies styled component themes.
 * In this comonent we also dictate site wide header config like meta tags
 */
const LocaleContext = React.createContext();

const Layout = ({
  children,
  pageType,
  destination,
  pageContext: { locale }
}) => {
  return (
    <LocaleContext.Provider value={{ locale }}>
      <ThemeProvider theme={baseTheme}>
        <>
          <Helmet>
            <html lang="en" />
            <link rel="dns-prefetch" href="//www.googletagmanager.com" />
            <link rel="dns-prefetch" href="//connect.facebook.net" />
            <link rel="dns-prefetch" href="//script.tapfiliate.com" />
            <link rel="dns-prefetch" href="//www.google-analytics.com" />
          </Helmet>
          <GlobalStyle />
          <Body>{children}</Body>
          <Footer />
          <CookieConsent>
            This website uses cookies for analytics, advertising amd to enhance
            your experience. <Link to="/about/cookie-policy/">More info.</Link>{' '}
            =>
          </CookieConsent>
          {/* <DataLayer event="Custom PageView" /> */}
        </>
      </ThemeProvider>
    </LocaleContext.Provider>
  );
};

export { LocaleContext };
export default Layout;
