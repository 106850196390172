import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Container, Button } from '../common';
import Gallery from './Gallery';
import media from '../layout/Media';
import { Link } from 'gatsby';
import useTranslations from '../layout/useTranslations';

const PriceBox = styled.div`
  display: flex;
  margin: 0;

  @media ${media.mobileL} {
    margin: 0 auto;
  }
`;

const AccomButton = styled(Button)`
  width: 100%;
  @media ${media.mobileL} {
    width: auto;
  }
`;

const Includes = styled.h5`
  margin-bottom: 0.5rem;
`;

const FlexContainer = styled.div`
  display: flex;
  max-width: ${props => props.theme.maxwidth};
  margin: 0 auto;
  flex-wrap: wrap;
  flex-direction: row;
`;

const AccomIntro = styled.div`
  align-self: center;
  margin-bottom: 1rem;
  margin-top: 1rem;

  p {
    margin-bottom: 0%;
  }
`;

const PriceFeatures = styled.div`
  align-self: center;
  margin-bottom: 1rem;

  p {
    margin-bottom: 0%;
  }
`;

const FlexBoxCollapse = styled.div`
  width: 100%;
  margin-right: 10px;
  margin-top: 10px;

  @media ${media.mobileL} {
    width: 46%;
  }
`;

const AccomBlock = styled.div``;

const BookSectionText = styled.div`
  margin: 0 0 1rem;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.4;
  padding-right: 1rem;
  @media ${media.tablet} {
    margin: 1rem 0 0.7rem;
  }
`;

const BookPriceInc = styled.div`
  background: ${props => props.theme.lightBlue};
  padding: 1rem;
`;

const Price = styled.div`
  font-size: 4rem;
  span {
    font-size: 1rem;
  }
`;

const SwipeContainer = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  max-width: 960px;
  margin: 0 auto;
  scroll-snap-coordinate: 0 0;
  scroll-snap-points-x: repeat(100%);
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  will-change: transform;
`;

/**
 * This component shows the accommodation info on the hostel page including the gallery, pricing, booking button
 */
const Accom = props => {
  // Randomly says how many people are on the website
  const people = Math.floor(Math.random() * 9) + 13;

  const {
    subline,
    bookNow,
    viewGallery,
    rooms,
    activities,
    facilities,
    location,
    faq,
    stay,
    dormRooms,
    privateRooms,
    checkAvail,
    bestPrice,
    socialSpot,
    activtySub,
    daytime,
    nighttime,
    stayfrom,
    includes,
    phone,
    email,
    address,
    privateCTA,
    dormCTA,
    perPerson
  } = useTranslations();

  return (
    <>
      {/* generating gallery object */}
      {props.source.map((block, index) => {
        const im2 = block.roomGallery.map(photo =>
          Object.assign({
            srcSet: photo.fluid.srcSet,
            src: photo.fluid.src,
            caption: photo.caption,
            fluid: photo.fluid,
            alt: photo.alt
          })
        );

        return (
          <AccomBlock key={block.id}>
            {block.model.apiKey === 'accom' && (
              <>
                <FlexContainer style={{ justifyContent: 'space-between' }}>
                  <AccomIntro>
                    <h3>{block.name}</h3>
                    <p>
                      <strong /> {block.introText.roomDescription}.
                    </p>
                  </AccomIntro>
                </FlexContainer>

                {/* Swipe container with images here */}

                <SwipeContainer>
                  <Gallery inline images={im2} />
                </SwipeContainer>

                <FlexContainer
                  style={{
                    marginTop: '1rem',
                    marginBottom: '1rem',
                    borderBottom: '1px solid #c7c7c7',
                    paddingBottom: '1rem'
                  }}
                >
                  <FlexBoxCollapse>
                    {index === 0 ? (
                      <BookSectionText>
                        There are {people} other people looking at staying at{' '}
                        {props.hostelName} now.
                      </BookSectionText>
                    ) : (
                      <BookSectionText>{privateCTA}</BookSectionText>
                    )}

                    {props.mewsProperty && (
                      <Link to="/book">
                        <AccomButton primary large>
                          {checkAvail}
                        </AccomButton>
                      </Link>
                    )}
                    <p>
                      <small>{bestPrice}</small>
                    </p>
                  </FlexBoxCollapse>

                  <PriceBox style={{ justifyContent: 'space-between' }}>
                    <PriceFeatures style={{ marginRight: '10px' }}>
                      <BookPriceInc>
                        {stayfrom}
                        <Price>
                          ${block.priceFrom}
                          <span>{block.currency}</span>
                        </Price>
                        {block.name === 'Private Rooms'
                          ? 'Per room/per night'
                          : 'Per person/per night'}
                      </BookPriceInc>
                    </PriceFeatures>
                    <PriceFeatures>
                      <div className="includes">
                        <Includes>{includes}:</Includes>
                        <div
                          dangerouslySetInnerHTML={{ __html: block.features }}
                        />
                      </div>
                    </PriceFeatures>
                  </PriceBox>
                </FlexContainer>
              </>
            )}
          </AccomBlock>
        );
      })}
    </>
  );
};

Accom.propTypes = {
  /**
   * Passes the name of the hostel - Sourced in DatoCMS */
  hostelName: PropTypes.string,
  /**
   * Pass the gallery object - Sourced in DatoCMS
   */
  source: PropTypes.array
};
export { Accom };
