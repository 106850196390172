import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { LocaleContext } from '../layout';

import { Button } from '../common';
import { Select } from './FormStyles';
import media from '../layout/Media';

const Wrapper = styled.div`
  @media all and (-ms-high-contrast: none) {
    form {
      display: none;
    } /* IE10 */
    *::-ms-backdrop,
    form {
      display: none;
    } /* IE11 */
  }
`;

const DownArrow = styled.div`
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;

  border-top: 6px solid #fff;
  float: right;
  margin-top: -20px;
  margin-right: 10px;
`;
const HeaderSelect = styled.div`
  height: 46px;
  /* overflow: hidden; */

  width: 100%;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 0px;
  border: 2px solid #fff;
  background: #333333a1;
  padding: 4px;
  display: inline-block;

  margin-right: 0px;
  @media ${media.tablet} {
    width: 315px;
    margin-right: 10px;
  }

  select {
    background: transparent;
    border: none;
    font-size: 1.1rem;
    color: #fff;
    height: 35px;
    padding: 5px; /* If you add too much padding here, the options won't show in IE */

    width: 95%;
    -webkit-appearance: none;
    @media ${media.tablet} {
      width: 280px;
    }
    option {
      color: #333;
    }
  }
`;

const FormButton = styled(Button)`
  width: 100%;
  margin-top: 1rem;
  @media ${media.tablet} {
    width: auto;
    margin-top: inherit;
  }
`;

/** This is the search form compnent which allows a user to search for hostels from the home page and from the booking button on the nave - when not a hostel page */
const HostelSearchForm = props => {
  const [hostelUrl, setHostelUrl] = useState('/deals/bed-hopper');

  const { locale } = React.useContext(LocaleContext);
  const prefix = locale === 'en' ? '' : `${locale}/`;

  const handleChange = event => {
    setHostelUrl(event.target.value);
  };
  const navigateToPage = event => {
    event.preventDefault();
    navigate(hostelUrl);
  };

  return (
    <Wrapper>
      <form onSubmit={() => navigateToPage(event)} role="link">
        <HeaderSelect>
          <Select
            id="hostelName"
            name="hostelName"
            value={hostelUrl}
            onChange={handleChange}
            aria-label="Which hostel do you want to stay in?"
          >
            <option value="">Where to</option>
            <option value="/deals/bed-hopper">
              I want to go everywhere - BedHopper Pass
            </option>

            {props.hostels.edges.map(({ node: hostel, index }) => (
              <option key={hostel.id} value={`${prefix}hostels/${hostel.slug}`}>
                {hostel.title}
              </option>
            ))}
          </Select>
          <DownArrow />
        </HeaderSelect>
        <FormButton primary type="submit">
          Let's go
        </FormButton>
      </form>
    </Wrapper>
  );
};

HostelSearchForm.propTypes = {
  /** Gets an array of hostels */
  hostels: PropTypes.object.isRequired
};

export { HostelSearchForm };
